import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  IconButton,
  Button,
  Grid,
  Card,
  Box,
  Switch,
  Select,
  List,
  CardContent,
  TextField,
  InputLabel,
  ListItem,
  ListItemText,
  FormControlLabel,
  MenuItem,
  FormGroup,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import SurveyQuestion from "./SurveyQuestion";
import QuestionDesigner from "./QuestionDesigner";
import { createMuiTheme } from "@material-ui/core/styles";
import { fade, ThemeProvider } from "@material-ui/core/styles";
import SurveyDataService from "../services/SurveyDataService";
import { useAppContext } from "../services/authContext";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        border: "1px solid #DEE7F6",
        minWidth: 200,
      },
      text: {
        color: "#999999",
      },
      label: {
        justifyContent: "left",
        marginLeft: 15,
      },
      endIcon: {
        marginLeft: 85,
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #DEE7F6",
        top: 1.3,
        font: "inherit",
      },
      input: {
        color: "#1A568E",
        textAlign: "left",
      },
    },
    MuiInput: {
      formControl: {
        border: 0,
      },
      underline: {
        "&:before": {
          borderBottom: 0,
        },
      },
    },
    MuiTypography: {
      body1: {
        color: "#1A568E",
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#235FC1",
          "&:hover": {
            color: "#235FC1",
          },
        },
      },
    },
    MuiTableCell: {
      root: {},
      head: {
        color: "#777777",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  search: {
    display: "inline",
    position: "relative",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,

    height: "100%",
  },
  searchIcon: {
    display: "inline",
    height: "100%",
    width: "20%",
    padding: "10px 15px 10px 15px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #388EF2",
    borderRadius: "0px 5px 5px 0px",
    background: "#388EF2",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: "100%",
    minWidth: 300,
  },
  issueBtn: {
    background: "#388EF2",
    borderRadius: 5,
    font: "normal normal 600 1em/ 1em Montserrat",
    color: "#F5F8FC",
    textTransform: "capitalize",
  },
  surveyHead: {
    backgroundColor: "#E1E5F4",
    padding: "10px 10px 10px 30px",
    font: "normal normal bold 12px / 9px Montserrat- Bold",
  },
}));

function SurveyDetail(props) {
  const classes = useStyles();
  const { displayMessage } = useAppContext();
  const initialQuestion = {
    type: 1,
    context: "",
    context_fr: "",
    mandatory: false,
    options: [],
  };
  const [designQuestion, setDesignQuestion] = useState(initialQuestion);

  const changeMandatory = (name) => (event) => {
    setDesignQuestion({ ...designQuestion, [name]: event.target.checked });
  };

  const changeText = (name) => (event) => {
    setDesignQuestion({ ...designQuestion, [name]: event.target.value });
  };

  const changeType = (event) => {
    setDesignQuestion({ ...initialQuestion, type: event.target.value });
  };

  const addOption = () => {
    let tmpQuestion = designQuestion;
    tmpQuestion.options = [
      ...tmpQuestion.options,
      { en: "", fr: "", correctAnswer: false },
    ];
    setDesignQuestion({ ...tmpQuestion });
  };

  const changeCorrectAnswer = (index) => (event) => {
    let tmpQuestion = designQuestion;
    if (tmpQuestion.type === 2) {
      tmpQuestion.options.forEach((option) => (option.correctAnswer = false));
    }
    tmpQuestion.options[index].correctAnswer = event.target.checked;
    setDesignQuestion({ ...tmpQuestion });
  };

  const deleteOption = (index) => (event) => {
    let tmpQuestion = designQuestion;
    tmpQuestion.options.splice(index, 1);
    setDesignQuestion({ ...tmpQuestion });
  };

  const changeOption = (name, index) => (event) => {
    let tmpQuestion = JSON.parse(JSON.stringify(designQuestion));
    tmpQuestion.options[index][name] = event.target.value;
    setDesignQuestion({ ...tmpQuestion });
  };

  const saveDesignQuestion = () => {
    let optionsStr = "[";
    if (designQuestion.options) {
      let begin = 0;
      designQuestion.options.map((element) => {
        if (0 === begin) {
          begin = 2;
        } else {
          optionsStr += ",";
        }
        optionsStr += '{"en": "' + element.en + '"';
        optionsStr += ',"fr": "' + element.fr + '"';
        optionsStr +=
          ',"correctAnswer": ' +
          (element.correctAnswer ? element.correctAnswer : false) +
          " }";
      });
    }
    optionsStr += "]";
    let tempQuestion = {
      creatorId: 22,
      context:
        '{"en":"' +
        designQuestion.context +
        '","fr":"' +
        designQuestion.context_fr +
        '"}',
      type: designQuestion.type,
      mandatory: designQuestion.mandatory,
      options: optionsStr,
    };
    let tempExistingQuestions = [];
    props.survey.surveyQuestions.map((el) =>
      tempExistingQuestions.push({
        creatorId: el.creatorId,
        context: el.context,
        type: el.type,
        mandatory: el.mandatory,
        options: el.options,
      })
    );

    SurveyDataService.updateSurvey({
      survey: {
        creatorId: props.survey.survey.creatorId,
        operatorId: props.survey.survey.operatorId,
        clinicId: props.survey.survey.clinicId,
        title: props.survey.survey.title,
        status: "DRAFT",
        id: props.survey.survey.id,
      },
      surveyQuestions: [...tempExistingQuestions, tempQuestion],
    }).then(
      (response) => {
        displayMessage("The question has been successfully added!");
        props.setQuestions([...props.questions, designQuestion]);
      },
      (error) => console.log(error)
    );
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Typography>
          <IconButton color="primary" onClick={props.showSurveyList}>
            <ArrowBackIosIcon fontSize="large" />
            Back
          </IconButton>
        </Typography>
        <Container>
          <Card>
            <CardContent>
              <Grid container style={{}}>
                <Grid item md={5} classes={{ root: classes.surveyHead }}>
                  <TextField
                    label="Title"
                    value={props.title}
                    style={{ color: "#3553B2" }}
                    onChange={props.handleTitleChange}
                    disabled={props.viewOnly}
                    fullWidth
                  />
                </Grid>
                <Grid item md={1} classes={{ root: classes.surveyHead }}>
                  {props.viewOnly ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={props.updateTitle}
                      disabled={props.viewOnly}
                    >
                      Update
                    </Button>
                  )}
                </Grid>
                <Grid item md={2} classes={{ root: classes.surveyHead }}></Grid>
                <Grid item md={1} classes={{ root: classes.surveyHead }}>
                  <TextField
                    label="from"
                    value={props.survey.survey.creatorId}
                    disabled
                  />
                </Grid>
                <Grid item md={2} classes={{ root: classes.surveyHead }}>
                  <TextField
                    label="Date"
                    value={props.survey.survey.createdDate}
                    disabled
                  />
                </Grid>
                <Grid item md={1} classes={{ root: classes.surveyHead }}>
                  <TextField
                    label="Id"
                    value={props.survey.survey.id}
                    disabled
                  />
                </Grid>
                <Grid item md={12} style={{ margin: "10px 30px 30px 50px" }}>
                  <SurveyQuestion
                    questions={props.questions}
                    editQuestion={props.handleQuestionEdit}
                    deleteQuestion={props.handleQuestionDelete}
                    viewOnly={props.viewOnly}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <Grid container spacing={3}>
              <Grid item md={6} style={{ padding: 30 }}>
                <Box
                  borderRight={1}
                  borderColor="grey.300"
                  style={{ padding: 10 }}
                >
                  <FormControl>
                    <FormControlLabel
                      value="mandatory"
                      control={
                        <Switch
                          color="primary"
                          checked={designQuestion.mandatory}
                          onChange={changeMandatory("mandatory")}
                        />
                      }
                      label="Mandatory to answer"
                      labelPlacement="start"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="question-type-label">Type</InputLabel>
                    <Select
                      labelId="question-type-label"
                      id="question-type"
                      value={designQuestion.type}
                      onChange={changeType}
                    >
                      <MenuItem value={1}>Question and Text Answer</MenuItem>
                      <MenuItem value={2}>Single Selection</MenuItem>
                      <MenuItem value={3}>Multiple Selections</MenuItem>
                      <MenuItem value={4}>Upload File</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Français"
                    margin="normal"
                    fullWidth
                    value={designQuestion.context_fr}
                    onChange={changeText("context_fr")}
                  />
                  <TextField
                    label="English"
                    margin="normal"
                    fullWidth
                    value={designQuestion.context}
                    onChange={changeText("context")}
                  />
                  {designQuestion.type === 2 || designQuestion.type === 3 ? (
                    <div>
                      <Typography variant="subtitle2" gutterBottom>
                        Answer Options:
                      </Typography>
                      <List
                        component="nav"
                        aria-label="Secondary mailbox folders"
                      >
                        {designQuestion.options.map((option, index) => (
                          <ListItem key={index}>
                            {" "}
                            <ListItemText primary={index + 1} />{" "}
                            <TextField
                              label="Français"
                              margin="normal"
                              value={option.fr}
                              fullWidth
                              className={classes.option}
                              onChange={changeOption("fr", index)}
                            />
                            <TextField
                              label="English"
                              margin="normal"
                              value={option.en}
                              fullWidth
                              className={classes.option}
                              onChange={changeOption("en", index)}
                            />
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={!designQuestion.mandatory}
                                    checked={
                                      designQuestion.options[index]
                                        .correctAnswer
                                    }
                                    color="primary"
                                    onChange={changeCorrectAnswer(index)}
                                  />
                                }
                                label="Must select"
                              />
                            </FormGroup>
                            <IconButton onClick={deleteOption(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItem>
                        ))}
                      </List>
                      <Grid container className={classes.paper}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={addOption}
                          >
                            Add Answer Option
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveDesignQuestion}
                  disabled={
                    (designQuestion.type === 3 || designQuestion.type === 2
                      ? designQuestion.options.length < 2 ||
                        designQuestion.options.find(
                          (option) => !(option.en || option.fr)
                        )
                      : false) ||
                    !(designQuestion.context_fr || designQuestion.context) //||
                    // !designQuestion.options.find(
                    //   (option) => option.en || option.fr
                    // )
                  }
                >
                  Add Question
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Container>
        <QuestionDesigner
          question={props.question}
          open={props.open}
          closeDesigner={props.closeDesigner}
          saveDesignedQuestion={props.saveDesignedQuestion}
          handleMandatoryChange={props.handleMandatoryChange}
          handleTextChange={props.handleTextChange}
          handleOptionChanged={props.handleOptionChanged}
          handleTypeChange={props.handleTypeChange}
          handleAddOption={props.handleAddOption}
          handleCorrectAnswer={props.handleCorrectAnswer}
          handleDeleteOption={props.handleDeleteOption}
        />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default SurveyDetail;
