import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  IconButton,
  Button,
  InputBase,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ReactComponent as SchIcon } from "../static/images/SVGs/searchIcon.svg";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { createMuiTheme } from "@material-ui/core/styles";
import { fade, ThemeProvider } from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TodayIcon from "@material-ui/icons/Today";
import SearchIcon from "@material-ui/icons/Search";
import PatientDataService from "../services/PatientDataService";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        border: "1px solid #DEE7F6",
        minWidth: 200,
      },
      text: {
        color: "#999999",
      },
      label: {
        justifyContent: "left",
        marginLeft: 15,
      },
      endIcon: {
        marginLeft: 85,
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #DEE7F6",
        top: 1.3,
      },
      input: {
        color: "#1A568E",
        textAlign: "left",
      },
    },
    MuiInput: {
      formControl: {
        border: 0,
      },
    },
    MuiTypography: {
      body1: {
        color: "#1A568E",
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#235FC1",
          "&:hover": {
            color: "#235FC1",
          },
        },
      },
    },
    MuiTableCell: {
      root: {},
      head: {
        color: "#777777",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  search: {
    display: "inline",
    position: "relative",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,

    height: "100%",
  },
  searchIcon: {
    display: "inline",
    height: "100%",
    width: "20%",
    padding: "10px 15px 10px 15px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #388EF2",
    borderRadius: "0px 5px 5px 0px",
    background: "#388EF2",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: "100%",
    minWidth: 300,
  },
  issueBtn: {
    background: "#388EF2",
    borderRadius: 5,
    font: "normal normal 600 1em/ 1em Montserrat",
    color: "#F5F8FC",
    textTransform: "capitalize",
  },
  v_center: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 30,
  },
}));

function SearchBar(props) {
  const name = props.name;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchKey, setSearchKey] = useState("1");
  const [searchValue, setSearchValue] = useState("");

  function changeSearchKey(event) {
    setSearchKey(event.target.value);
  }

  function changeSearchValue(event) {
    setSearchValue(event.target.value);
  }

  function search() {
    let patient = {};
    if (searchKey === "1") {
      patient = { firstName: searchValue };
    }
    if (searchKey === "2") {
      patient = { lastName: searchValue };
    }
    if (searchKey === "3") {
      patient = { mediCardNumber: searchValue };
    }
    if (searchKey === "4") {
      patient = { contactPhone: searchValue };
    }
    PatientDataService.searchPatient(patient).then(
      (response) => {
        props.setPatientList(response.data);
      },
      (error) => console.log(error)
    );
  }

  function reset() {
    setSearchValue("");
    props.populateClinicPatients();
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Grid container style={{ backgroundColor: "#F0F4FA", height: 70 }}>
          <Grid item md={7} classes={{ root: classes.v_center }}>
            {/* <Button
              classes={{ root: classes.borderRadius }}
              style={{ backgroundColor: "#ffffff" }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {props.name}
            </Button> */}
            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Doctor</MenuItem>
              <MenuItem onClick={handleClose}>Patient</MenuItem>
              <MenuItem onClick={handleClose}>Clinic</MenuItem>
            </Menu> */}

            {/* <div className={classes.search}>
              <InputBase
                style={{ backgroundColor: "#ffffff" }}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            <div className={classes.searchIcon}>
              <SchIcon />
            </div> */}
            {props.setPatientList ? (
              <Grid>
                <FormControl className={classes.formControl}>
                  <InputLabel id="category-label">Key Word</InputLabel>
                  <Select
                    id="category"
                    name="category"
                    label="Category"
                    value={searchKey}
                    onChange={changeSearchKey}
                  >
                    <MenuItem key="1" value="1">
                      First Name
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      Last Name
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      Medical Card
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      Phone
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="category-label">Search</InputLabel>
                  <OutlinedInput
                    id="searchBox"
                    name="searchBox"
                    value={searchValue}
                    onChange={changeSearchValue}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={search}>
                          <SearchIcon color="primary" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* <FormControl style={{ marginTop: 10 }}>
                  <Button variant="contained" onClick={reset} color="primary">
                    Reset
                  </Button>
                </FormControl> */}
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          {name.toLowerCase() === "survey" ? (
            <Grid
              item
              md={4}
              classes={{ root: classes.v_center }}
              align="right"
            >
              <Button
                onClick={props.openNewIssue}
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                classes={{
                  root: classes.issueBtn,
                }}
              >
                New survey
              </Button>
            </Grid>
          ) : name.toLowerCase() === "appointment" ? (
            <Grid
              item
              md={4}
              classes={{ root: classes.v_center }}
              align="right"
            >
              <IconButton
                color={props.isApplist ? "default" : "primary"}
                onClick={props.changeAppShow}
              >
                <ListAltIcon />
              </IconButton>
              <IconButton
                onClick={props.changeCalendarShow}
                color={props.isApplist ? "primary" : "default"}
              >
                <TodayIcon />
              </IconButton>
              <Button
                onClick={props.openNewIssue}
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                classes={{
                  root: classes.issueBtn,
                }}
              >
                New Appointment
              </Button>
            </Grid>
          ) : name.toLowerCase() === "doctor" ? (
            <Grid
              item
              md={4}
              classes={{ root: classes.v_center }}
              align="right"
            >
              <Button
                onClick={props.openNewIssue}
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                classes={{
                  root: classes.issueBtn,
                }}
              >
                New Doctor
              </Button>
            </Grid>
          ) : name.toLowerCase() === "clinic" ? (
            <Grid
              item
              md={4}
              classes={{ root: classes.v_center }}
              align="right"
            >
              <Button
                onClick={props.openNewIssue}
                variant="contained"
                color="primary"
                startIcon={<CancelOutlinedIcon />}
                classes={{
                  root: classes.issueBtn,
                }}
              >
                Report an Issue
              </Button>
            </Grid>
          ) : (
            <Grid
              item
              md={4}
              classes={{ root: classes.v_center }}
              align="right"
            >
              <Button
                onClick={props.openNewPat}
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                classes={{
                  root: classes.issueBtn,
                }}
              >
                New patient
              </Button>
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default SearchBar;
