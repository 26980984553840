// import axios from "axios";
import axios from "../api/axios";
import { BASE_URL } from "./AuthService";

class SurveyDataService {
  createSurvey(survey) {
    return axios.post(`${BASE_URL}/surveys/with-questions`, survey);
  }

  findSurveyForApp(conditions) {
    return axios.post(`${BASE_URL}/surveys/search`, conditions);
  }

  findAllSurveys(clinicId, page, size) {
    return axios.get(
      `${BASE_URL}/clinics/${clinicId}/surveys?page=${page}&size=${size}`
    );
  }

  findAllClinicSurveys(clinicId, surveyStatus) {
    return axios.get(
      `${BASE_URL}/clinics/${clinicId}/allsurveys` +
        (surveyStatus ? "?status=" + surveyStatus : "")
    );
  }

  findSurveyById(surveyId) {
    return axios.get(`${BASE_URL}/surveys/${surveyId}/questions`);
  }

  disableSurvey(surveyId) {
    return axios.delete(`${BASE_URL}/surveys/${surveyId}`);
  }

  changeSurveyStatus(surveyId, status) {
    return axios.put(
      `${BASE_URL}/surveys/${surveyId}/status?statusValue=${status}`
    );
  }

  findSurveyByAppId(appointmentId) {
    return axios.get(`${BASE_URL}/surveys/appointment/${appointmentId}`);
  }

  updateQuestion(question) {
    return axios.put(`${BASE_URL}/surveyQuestions`, question);
  }

  updateSurveyTitle(survey) {
    return axios.put(`${BASE_URL}/surveys`, survey);
  }

  updateSurvey(survey) {
    return axios.post(`${BASE_URL}/surveys/with-questions`, survey);
  }
}

export default new SurveyDataService();
