import React, { useState } from "react";
import {
  Stepper,
  Paper,
  Step,
  StepLabel,
  Button,
  makeStyles,
  Container,
  Typography,
  Divider,
  FormLabel,
  FormControl,
  IconButton,
  OutlinedInput,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DoctorDataService from "../services/DoctorDataService";
import StepConnector from "@material-ui/core/StepConnector";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useAppContext } from "../services/authContext";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import "./NewDoctorStyle.css";

function getSteps() {
  return [
    "Medical Card Number",
    "General Info",
    "Registration",
    "Additional Info",
  ];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "40%",
  },
  button: {
    margin: theme.spacing(2),
  },
  tableCellTop: {
    verticalAlign: "top",
  },
  iconContainer: {
    transform: "scale(1.5)",
  },
  step_label_root: {
    fontSize: "18px",
    marginLeft: "15px",
  },
  generalInput: {
    padding: "10px",
  },
  tabTitle: {
    padding: 30,
    fontWeight: 700,
    color: "#1A568E",
  },
  inputTitle: {
    padding: 10,
    fontWeight: 700,
    color: "#1A568E",
  },
  inputInput: {
    padding: "0 16px",
    backgroundColor: "#EFF1F7",
  },
  textField: {
    height: 40,
    backgroundColor: "#EFF1F7",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function NewDoctor(props) {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [newDoctor, setNewDoctor] = useState({});
  const [enDescription, setEnDescription] = useState("");
  const [frDescription, setFrDescription] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [errors, setErrors] = useState({});
  const { displayMessage, clinicId, displayAlert } = useAppContext();
  const [dateExp, setDateExp] = useState(null);
  const [dateBd, setDateBd] = useState(null);
  const [uploadFile, setUploadFile] = useState("");

  function handleFileChange(event) {
    setUploadFile(event.target.files[0]);
  }

  function findDoctors() {
    DoctorDataService.searchDoctor(newDoctor).then(
      (response) => setCandidates(response.data),
      (error) => console.log(error)
    );
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function isEmpty(item) {
    return !item || item.length === 0;
  }

  function validateMCN() {
    if (isEmpty(newDoctor.mediCardNumber)) {
      setErrors({
        ...errors,
        mediCardNumber: "Medical card number cannot be empty",
      });
      return false;
    }
    return true;
  }

  function validateDoctor() {
    let tempErrors = {};
    // if (isEmpty(newDoctor.mediCardExp)) {
    //   tempErrors = {
    //     ...tempErrors,
    //     mediCardExp: "Expiration is required.",
    //   };
    // }
    if (isEmpty(newDoctor.firstName)) {
      tempErrors = {
        ...tempErrors,
        firstName: "First name is required.",
      };
    }
    if (isEmpty(newDoctor.lastName)) {
      tempErrors = {
        ...tempErrors,
        lastName: "Last name is required.",
      };
    }
    if (isEmpty(newDoctor.contactPhone)) {
      tempErrors = {
        ...tempErrors,
        contactPhone: "Work phone is required.",
      };
    } else {
      const pattern = "^[0-9]*$";
      let re = new RegExp(pattern);
      if (!re.test(newDoctor.contactPhone)) {
        tempErrors = {
          ...tempErrors,
          contactPhone: "Work phone wrong format.",
        };
      }
    }
    // if (isEmpty(newDoctor.dateOfBirth)) {
    //   tempErrors = {
    //     ...tempErrors,
    //     dateOfBirth: "Date of birth is required.",
    //   };
    // }
    if (isEmpty(newDoctor.email)) {
      tempErrors = {
        ...tempErrors,
        email: "Email is required.",
      };
    }
    setErrors({ ...tempErrors });
    return Object.keys(tempErrors).length === 0;
  }

  function handleNext() {
    if (0 === activeStep) {
      if (!validateMCN()) {
        return;
      }
      console.log(activeStep);
      DoctorDataService.searchDoctor(newDoctor).then(
        (response) => {
          if (response.data.length !== 0) {
            if (
              window.confirm(
                "The doctor has already existed. Do you want to link him to this clinic?"
              )
            ) {
            } else {
              props.closeNewDoc();
            }
            DoctorDataService.linkDoc2Clinic(clinicId, {
              doctorId: response.data[0].doctorId,
              status: "ACCEPT_ALL",
              phone: response.data[0].contactPhone,
              phoneExt: response.data[0].contactPhoneExt,
              description: response.data[0].introduction,
            }).then(
              (response) => {
                props.populateClinicDoctors();
                displayMessage("The new doctor has been successfully created.");
                props.closeNewDoc();
              },
              (error) => console.log(error)
            );
            //setNewDoctor(response.data[0]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (1 === activeStep) {
      if (!validateDoctor()) {
        return;
      }
    }
    if (3 === activeStep) {
      DoctorDataService.createDoctor({
        ...newDoctor,
        introduction: JSON.stringify({ en: enDescription, fr: frDescription }),
        identityNo: "adfaf",
        clinicId: clinicId,
        //contactPhone: "12124214",
        middleName: "M",
        gender: "1",
        //contactPhoneExt: "34",
        provinceId: "QC",
      }).then(
        (response) => {
          const data = new FormData();
          data.append("file", uploadFile);
          console.log(data);
          DoctorDataService.uploadDoctorPhoto(
            response.data.doctorId,
            data
          ).then(
            (uploadedResponse) => {
              props.populateClinicDoctors();
              displayMessage("The new doctor has been successfully created.");
              props.closeNewDoc();
            },
            (uploadedError) => console.log(uploadedError)
          );
        },
        (error) => {
          displayAlert(error.response.data.message);
          console.log(error);
          setActiveStep(activeStep - 1);
          return;
        }
      );
    }
    setActiveStep(activeStep + 1);
  }

  function handleDateChangeExp(date) {
    setDateExp(date);
    setNewDoctor({
      ...newDoctor,
      mediCardExp: moment(date).format("YYYY-MM-DD"),
    });
  }

  function handleDateChangeBd(date) {
    setDateBd(date);
    setNewDoctor({
      ...newDoctor,
      dateOfBirth: moment(date).format("YYYY-MM-DD"),
    });
  }

  function resetPage() {
    setNewDoctor({
      ...newDoctor,
      firstName: "",
      lastName: "",
      //workPhone: "",
      email: "",
      postcode: "",
      streetNumber: "",
      streetName: "",
      city: "",
      country: "",
      mediCardExp: "",
      dateOfBirth: "",
      contactPhone: "",
      contactPhoneExt: "",
    });
    setDateExp(null);
    setDateBd(null);
  }

  const changeFrDescription = (event) => {
    setFrDescription(event.target.value);
  };

  const changeEnDescription = (event) => {
    setEnDescription(event.target.value);
  };

  const changeDoctorInfo = (keyWord) => (event) => {
    setNewDoctor({ ...newDoctor, [keyWord]: event.target.value });
  };

  const changePhoneType = (type) => (event) => {
    setNewDoctor({ ...newDoctor, contactPhoneType: type });
  };

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      fontWeight: "700",
      "& $line": {
        backgroundColor: "#3553B2",
      },
    },
    completed: {
      "& $line": {
        backgroundColor: "#3553B2",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
      width: "80%",
      margin: "0 auto",
    },
  })(StepConnector);

  return (
    <React.Fragment>
      <Typography align="right">
        <IconButton color="primary" onClick={props.closeNewDoc}>
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </Typography>
      <Stepper
        activeStep={activeStep}
        style={{ backgroundColor: "transparent" }}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                classes={{
                  iconContainer: classes.iconContainer,
                  label: classes.step_label_root,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Paper>
        <form>
          <Container
            style={{
              display: activeStep === 0 ? "block" : "none",
              minHeight: 400,
              marginTop: "16px",
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 30 }}
            >
              <Typography className={classes.inputTitle}>
                Medical Card Number
              </Typography>
              <OutlinedInput
                error={errors.mediCardNumber ? true : false}
                id="searchBox"
                name="searchBox"
                classes={{ input: classes.generalInput }}
                className={classes.inputInput}
                value={newDoctor.mediCardNumber}
                onChange={changeDoctorInfo("mediCardNumber")}
              />
            </FormControl>
            {errors.mediCardNumber && (
              <Typography
                variant="subtitle1"
                style={{ color: "red", paddingLeft: "16px" }}
              >
                {errors.mediCardNumber}
              </Typography>
            )}
          </Container>
          <Container
            style={{
              display: activeStep === 1 ? "block" : "none",
              minHeight: 400,
              marginTop: "16px",
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 50 }}>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Medical card number
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.mediCardNumber}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Expiration
                  </FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className="group-item"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      minDate={new Date()}
                      value={dateExp}
                      onChange={handleDateChangeExp}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      error={errors.mediCardExp ? true : false}
                      helperText={errors.mediCardExp}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    First name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.firstName}
                    onChange={changeDoctorInfo("firstName")}
                    error={errors.firstName ? true : false}
                    helperText={errors.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Last name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.lastName}
                    onChange={changeDoctorInfo("lastName")}
                    error={errors.lastName ? true : false}
                    helperText={errors.lastName}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                {newDoctor.contactPhoneType === "CELL" ? (
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item md={4}>
                        <FormControl fullWidth>
                          <FormLabel className={classes.inputTitle}>
                            Mobile Phone
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={newDoctor.contactPhone}
                            onChange={changeDoctorInfo("contactPhone")}
                            error={errors.contactPhone ? true : false}
                            helperText={errors.contactPhone}
                            style={{ marginRight: 30 }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}></Grid>
                      <Grid item md={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={newDoctor.contactPhoneType === "CELL"}
                              onChange={changePhoneType("LAND")}
                              name="mobilephone"
                              color="primary"
                            />
                          }
                          label="Mobile"
                          labelPlacement="top"
                        />
                      </Grid>
                      <Grid item md={6}></Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item md={12}>
                    <Grid container>
                      <Grid item md={4}>
                        <FormControl fullWidth>
                          <FormLabel className={classes.inputTitle}>
                            Phone
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={newDoctor.contactPhone}
                            onChange={changeDoctorInfo("contactPhone")}
                            error={errors.contactPhone ? true : false}
                            helperText={errors.contactPhone}
                            style={{ marginRight: 30 }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl fullWidth>
                          <FormLabel className={classes.inputTitle}>
                            Ext
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={newDoctor.contactPhoneExt}
                            onChange={changeDoctorInfo("contactPhoneExt")}
                            style={{ marginRight: 30 }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={newDoctor.contactPhoneType === "CELL"}
                              onChange={changePhoneType("CELL")}
                              name="Mobile"
                              color="primary"
                            />
                          }
                          label="Mobile"
                          labelPlacement="top"
                        />
                      </Grid>
                      <Grid item md={3}></Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Date of birth
                  </FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className="group-item"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      useCurrent={false}
                      maxDate={new Date()}
                      value={dateBd}
                      onChange={handleDateChangeBd}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      error={errors.dateOfBirth ? true : false}
                      helperText={errors.dateOfBirth}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Email</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.email}
                    onChange={changeDoctorInfo("email")}
                    error={errors.email ? true : false}
                    helperText={errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    PostalCode / Zip
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.postcode}
                    onChange={changeDoctorInfo("postcode")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Street Number
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.streetNumber}
                    onChange={changeDoctorInfo("streetNumber")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Street Name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.streetName}
                    onChange={changeDoctorInfo("streetName")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>City</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.city}
                    onChange={changeDoctorInfo("city")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Country</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newDoctor.country}
                    onChange={changeDoctorInfo("country")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={10}></Grid>
            </Grid>
            {/* <Container style={{ display: "flex", justifyContent: "right" }}>
              <Button
                variant="contained"
                onClick={findDoctors}
                style={{
                  marginTop: 30,
                  marginBottom: 10,
                  color: "#000000",
                  backgroundColor: "#f0f8ff",
                  width: "72px",
                  height: "30px",
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                onClick={resetPage}
                style={{
                  marginTop: 30,
                  marginLeft: 20,
                  marginBottom: 10,
                  color: "#000000",
                  backgroundColor: "#fff8dc",
                  width: "72px",
                  height: "30px",
                }}
              >
                Reset
              </Button>
            </Container> */}
          </Container>
          <Container
            style={{
              display: activeStep === 2 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 50 }}>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Medical card number
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newDoctor.mediCardNumber}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Expiration
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newDoctor.mediCardExp}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    First name
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newDoctor.firstName}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Last name
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newDoctor.lastName}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Phone</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newDoctor.contactPhone}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Date of birth
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newDoctor.dateOfBirth}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Email</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newDoctor.email}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Address</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>
                    {newDoctor.streetNumber} {newDoctor.streetName},{" "}
                    {newDoctor.city}, {newDoctor.country}, {newDoctor.postcode}
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
            </Grid>
          </Container>
          <Container
            style={{
              display: activeStep === 3 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <Grid container className="add-info-section">
              <Grid item md={6} className="add-info-left">
                <FormControl className="left-content">
                  <Avatar
                    src="/static/images/clinic.svg"
                    className="big-icon"
                  />
                  <TextField type="file" onChange={handleFileChange} />
                </FormControl>
              </Grid>
              <Grid item md={6} className="add-info-right">
                <Typography variant="h6" style={{ marginTop: 40 }}>
                  Introduction
                </Typography>
                <AppBar
                  position="static"
                  color="default"
                  style={{ marginTop: 10 }}
                >
                  <Tabs
                    value={currentTabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="French" {...a11yProps(0)} />
                    <Tab label="English" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={currentTabIndex} index={0}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={8}
                    variant="outlined"
                    value={frDescription}
                    onChange={changeFrDescription}
                    fullWidth
                  />
                </TabPanel>
                <TabPanel value={currentTabIndex} index={1}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={8}
                    variant="outlined"
                    value={enDescription}
                    onChange={changeEnDescription}
                    fullWidth
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </Container>
          <Container
            style={{
              display: activeStep === steps.length ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={handleBack}
              className={classes.button}
              disabled={activeStep === steps.length - 1}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </Button>
          </Container>
        </form>
      </Paper>
    </React.Fragment>
  );
}

export default NewDoctor;
