import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  option: {
    margin: theme.spacing(0, 2),
  },
  formControl: {
    minWidth: 222,
  },
}));

function QuestionDesigner(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.closeDesigner}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="h5">Question:</Typography>
            </Grid>
            <Grid item xs={3}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.question.mandatory}
                      onChange={props.handleMandatoryChange("mandatory")}
                      value="mandatory"
                      color="primary"
                    />
                  }
                  label="Mandatory to answer"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="smstimes">Question Type</InputLabel>
            <Select
              disabled={props.questionEditing}
              value={props.question.type}
              onChange={props.handleTypeChange}
              inputProps={{
                name: "smstimes",
                id: "smstimes",
              }}
            >
              <MenuItem value={1}>Quesiton and Text Answer</MenuItem>
              <MenuItem value={2}>Single Selection</MenuItem>
              <MenuItem value={3}>Multiple Selections</MenuItem>
              <MenuItem value={4}>Upload File</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Français"
            margin="normal"
            fullWidth
            value={props.question.context_fr}
            onChange={props.handleTextChange("context_fr")}
          />
          <TextField
            label="English"
            margin="normal"
            fullWidth
            value={props.question.context}
            onChange={props.handleTextChange("context")}
          />

          {props.question.type === 2 || props.question.type === 3 ? (
            <div>
              <Typography variant="subtitle2" gutterBottom>
                Answer Options:
              </Typography>
              <List component="nav" aria-label="Secondary mailbox folders">
                {props.question.options.map((option, index) => (
                  <ListItem key={index}>
                    {" "}
                    <ListItemText primary={index + 1} />{" "}
                    <TextField
                      label="Français"
                      margin="normal"
                      value={option.fr}
                      fullWidth
                      className={classes.option}
                      onChange={props.handleOptionChanged("fr", index)}
                    />
                    <TextField
                      label="English"
                      margin="normal"
                      value={option.en}
                      fullWidth
                      className={classes.option}
                      onChange={props.handleOptionChanged("en", index)}
                    />
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={!props.question.mandatory}
                            checked={
                              props.question.options[index].correctAnswer
                            }
                            color="primary"
                            onChange={props.handleCorrectAnswer(index)}
                          />
                        }
                        label="Must select"
                      />
                    </FormGroup>
                    <IconButton onClick={props.handleDeleteOption(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <Grid container className={classes.paper}>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={props.handleAddOption}
                  >
                    Add Answer Option
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={props.saveDesignedQuestion}
            color="primary"
          >
            Save
          </Button>
          <Button variant="contained" onClick={props.closeDesigner}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default QuestionDesigner;
