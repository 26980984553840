import React, { useState, useEffect } from "react";
import {
  makeStyles,
  IconButton,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { Delete, Edit } from "@material-ui/icons";
import SearchBar from "./SearchBar";
import TablePagination from "@material-ui/core/TablePagination";
import VisibilityIcon from '@material-ui/icons/Visibility';
import "./ContentListStyle.css"; 

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  head: {
    backgroundColor: "#e2e7ef",
  },
  tHead: {
    fontWeight: 600,
    color: "#777",
  },
  newDoctorBtn: {
    background: "#388EF2",
    borderRadius: 5,
    color: "#F5F8FC",
    textTransform: "capitalize",
  },
}));

function PatientList(props) {
  const classes = useStyles();
  const [pg, setPg] = React.useState(0);
  const totalPageNumber = Math.ceil(props.totalElement / props.rowsPerPage);

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
    setPg(newPage);
    console.log("Page", newPage + 1, "of", totalPageNumber);
    props.populateClinicPatients(newPage, props.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
    props.populateClinicPatients(0, event.target.value);
  };

  return (
    <React.Fragment>
      {/* <Grid container>
        <Grid item md={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Patient</InputLabel>
            <Select
              id="category"
              name="category"
              label="Category"
              variant="outlined"
              value={"00"}
            >
              <MenuItem key="1" value="1">
                Patient name
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Search</InputLabel>
            <OutlinedInput
              id="searchBox"
              name="searchBox"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={6} align="right">
          <Button
            onClick={props.openNewPat}
            variant="contained"
            color="primary"
            className={classes.newDoctorBtn}
            startIcon={<AddCircleOutlineIcon />}
          >
            New patient
          </Button>
        </Grid>
      </Grid> */}
      <SearchBar
        name="patient"
        openNewPat={props.openNewPat}
        setPatientList={props.setPatientList}
        populateClinicPatients={props.populateClinicPatients}
      />
      <Table className="content-table patient-table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell className={"col-title icon-col " + classes.tHead}></TableCell>
            <TableCell className={"col-title large-col " + classes.tHead}>Patient</TableCell>
            <TableCell className={"col-title medium-col phone-col" + classes.tHead}>ID</TableCell>
            <TableCell className={"col-title large-col " + classes.tHead}>Medical card number</TableCell>
            <TableCell className={"col-title medium-col " + classes.tHead}>Contact</TableCell>
            <TableCell className={"action-col " + classes.tHead}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.patientList.length > 0
            ? props.patientList.map((patient) => (
                <TableRow key={patient.patientId}>
                  <TableCell className="col-content icon-col">
                    <Avatar src={patient.photoUrl} />
                  </TableCell>
                  <TableCell className="col-content bold-font large-col">
                    {patient.middleName == null
                      ? patient.firstName + " " + patient.lastName
                      : patient.firstName +
                        " " +
                        patient.middleName +
                        " " +
                        patient.lastName}
                  </TableCell>
                  <TableCell className="col-content medium-col patient-col">{patient.patientId}</TableCell>
                  <TableCell className="col-content large-col">{patient.mediCardNumber}</TableCell>
                  <TableCell className="col-content medium-col">{patient.email}</TableCell>
                  <TableCell className="col-content action-col">
                    <IconButton>
                      <VisibilityIcon 
                        color="primary" variant="contained"
                        onClick={props.displayDetails(patient.patientId)}/>
                    </IconButton>
                    {/* <IconButton
                      onClick={props.displayDetails(patient.patientId)}
                    >
                      <Edit />
                    </IconButton> */}
                    {/* <IconButton>
                      <Delete />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))
            : ""}
        </TableBody>
      </Table>
      {props.patientList.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.totalElement}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default PatientList;
