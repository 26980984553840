import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { AppContext } from "./services/authContext";
import Login from "./components/Login";
import Backend from "./components/Backend";
import { Switch, Route } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

function App() {
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertInfo, setAlertInfo] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [clinicId, setClinicId] = useState();
  const [clinicTZs, setClinicTZs] = useState(new Map());

  function handleMsgClose() {
    setMessageOpen(false);
  }

  function displayMessage(message) {
    setMessage(message);
    setMessageOpen(true);
  }

  function handleAlertClose() {
    setAlertOpen(false);
  }

  function displayAlert(message) {
    setAlertInfo(message);
    setAlertOpen(true);
  }

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      localStorage.clear();
      // console.log('beforeunload event triggered');
      // return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <BrowserRouter>
      <AppContext.Provider
        value={{
          messageOpen,
          displayMessage,
          alertInfo,
          displayAlert,
          clinicId,
          setClinicId,
          clinicTZs,
          setClinicTZs,
        }}
      >
        <Snackbar
          open={messageOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleMsgClose}
        >
          <MuiAlert variant="filled" severity="success">
            {message}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleAlertClose}
        >
          <MuiAlert variant="filled" severity="error">
            {alertInfo}
          </MuiAlert>
        </Snackbar>
        <Switch>
          <Route exact path="/login" component={Login} />
          <AuthenticatedRoute path="/" component={Backend} />
        </Switch>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
