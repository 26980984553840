import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0, 3),
  },
  question: {
    margin: theme.spacing(3, 0, 0, 0),
  },
}));

function TextQuestionComponent(props) {
  const classes = useStyles();

  return (
    <Typography>
      {props.question.mandatory ? <b style={{ color: "red" }}>*</b> : ""}
      {props.index + 1}.{" "}
      {props.question.type === 4 ? <b>Upload Question:</b> : ""}{" "}
      {props.question.context_fr}? / {props.question.context}?
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={props.editQuestion(props.index)}
      >
        Edit
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={props.deleteQuestion(props.index)}
      >
        Delete
      </Button>
    </Typography>
  );
}

function MultiAnswerQuestion(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.question}>
        {props.question.mandatory ? <b style={{ color: "red" }}>*</b> : ""}
        {props.index + 1}. {props.question.context_fr}? /
        {props.question.context}?
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={props.editQuestion(props.index)}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.deleteQuestion(props.index)}
        >
          Delete
        </Button>
      </Typography>
      <FormGroup row>
        {props.question.options.map((option, index) => (
          <FormControlLabel
            control={<Checkbox color="primary" disabled />}
            label={`${option.fr} / ${option.en}`}
            key={index}
          />
        ))}
      </FormGroup>
    </div>
  );
}

function SingleAnswerQuestion(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.question}>
        {props.question.mandatory ? <b style={{ color: "red" }}>*</b> : ""}
        {props.index + 1}. {props.question.context_fr}? /
        {props.question.context}?
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={props.editQuestion(props.index)}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.deleteQuestion(props.index)}
        >
          Delete
        </Button>
      </Typography>
      <RadioGroup aria-label="Gender" name="gender1" row>
        {props.question.options.map((option, index) => (
          <FormControlLabel
            key={index}
            value="female"
            control={<Radio disabled />}
            label={`${option.fr} / ${option.en}`}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

function QuestionComponent(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        {props.questions.map((question, index) =>
          question.type === 1 || question.type === 4 ? (
            <TextQuestionComponent
              question={question}
              index={index}
              editQuestion={props.editQuestion}
              deleteQuestion={props.deleteQuestion}
              key={index}
            />
          ) : question.type === 2 ? (
            <SingleAnswerQuestion
              question={question}
              index={index}
              editQuestion={props.editQuestion}
              deleteQuestion={props.deleteQuestion}
              key={index}
            />
          ) : (
            <MultiAnswerQuestion
              question={question}
              index={index}
              editQuestion={props.editQuestion}
              deleteQuestion={props.deleteQuestion}
              key={index}
            />
          )
        )}
      </CardContent>
    </Card>
  );
}

export default QuestionComponent;
