import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import NewSurvey from "./NewSurvey";
import SurveysInfo from "./SurveysInfo";
import SurveyDataService from "../services/SurveyDataService";
import { useAppContext } from "../services/authContext";

function Surveys(props) {
  const [newSurvey, setNewSurvey] = useState(false);
  const { clinicId } = useAppContext();
  const [surveyList, setSurveyList] = useState([]);
  const [totalElement, setTotalElement] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    populateSurveys(page, rowsPerPage);
  }, []);

  function populateSurveys(pg, sz) {
    SurveyDataService.findAllSurveys(clinicId, pg, sz).then(
      (response) => {
        setSurveyList(response.data.content);
        setTotalElement(response.data.totalElements);
      },
      (error) => console.log(error)
    );
  }

  function openNewSurvey() {
    setNewSurvey(true);
  }

  function closeNewSurvey() {
    setNewSurvey(false);
  }

  return (
    <Box>
      {newSurvey ? (
        <NewSurvey
          closeNewSurvey={closeNewSurvey}
          populateSurveys={populateSurveys}
          rowsPerPage={rowsPerPage}
        />
      ) : (
        <SurveysInfo
          openNewSurvey={openNewSurvey}
          surveyList={surveyList}
          populateSurveys={populateSurveys}
          totalElement={totalElement}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </Box>
  );
}

export default Surveys;
