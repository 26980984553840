import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Input,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Select,
  Button,
  Table,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Container,
  MenuItem,
  Card,
  CardContent,
  Divider,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ClinicDataService from "../services/ClinicDataService";
import { createMuiTheme } from "@material-ui/core/styles";
import { fade, ThemeProvider } from "@material-ui/core/styles";
import Image from "material-ui-image";
import { ReactComponent as PhoneIcon } from "../static/images/SVGs/phone.svg";
import { ReactComponent as PhoneCallIcon } from "../static/images/SVGs/phoneCall.svg";
import { ReactComponent as EmailIcon } from "../static/images/SVGs/IconEnvelope.svg";
import { ReactComponent as PencileIcon } from "../static/images/SVGs/pencile.svg";
import SearchBar from "./SearchBar";
import SmsIcon from "@material-ui/icons/Sms";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useAppContext } from "../services/authContext";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        border: "1px solid #DEE7F6",
        minWidth: 200,
      },
      text: {
        color: "#999999",
      },
      label: {
        justifyContent: "left",
        marginLeft: 15,
      },
      endIcon: {
        marginLeft: 85,
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #DEE7F6",
        top: 1.3,
      },
      input: {
        color: "#1A568E",
        textAlign: "left",
      },
    },
    MuiInput: {
      formControl: {
        border: 0,
      },
    },
    MuiTypography: {
      body1: {
        color: "#1A568E",
      },
      displayBlock: {
        fontSize: 14,
        color: "#ffffff",
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#235FC1",
          "&:hover": {
            color: "#235FC1",
          },
        },
      },
    },
    MuiTableCell: {
      root: {},
      head: {
        color: "#777777",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  borderRadius: {
    borderRadius: "5px 0px 0px 5px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  search: {
    display: "inline",
    position: "relative",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    height: "100%",
  },
  days: {
    "& input": {
      textAlign: "center",
      backgroundColor: "#ffffff",
    },
  },
  searchIcon: {
    display: "inline",
    height: "100%",
    width: "20%",
    padding: "10px 15px 10px 15px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #388EF2",
    borderRadius: "0px 5px 5px 0px",
    background: "#388EF2",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: "100%",
    minWidth: 300,
  },
  issueBtn: {
    background: "#388EF2",
    borderRadius: 5,
    font: "normal normal 600 1em/ 1em Montserrat",
    color: "#F5F8FC",
    textTransform: "capitalize",
  },
  languageTabs: {},
  centerInput: {
    textAlign: "center",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={8}
          value={props.intro}
          defaultValue="Default Value"
          variant="outlined"
          fullWidth
        />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabCardPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card>
          <CardContent style={{ padding: 50 }}>{children}</CardContent>
        </Card>
      )}
    </div>
  );
}

TabCardPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function IntroPanel(props) {
  const { children, value, index, ...other } = props;
  const [languageTab, setLanguageTab] = useState(0);

  function handleLanguageTabChange(event, newValue) {
    setLanguageTab(newValue);
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card style={{ width: "92%" }}>
          <CardContent style={{ padding: 30 }}>
            <AppBar
              position="static"
              color="default"
              style={{
                width: "19%",
                marginLeft: "auto",
                padding: "auto",
                backgroundColor: "#ffffff",
              }}
            >
              <Tabs
                value={languageTab}
                onChange={handleLanguageTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label="French"
                  {...a11yProps(0)}
                  style={{
                    minWidth: 0,
                    border: "0.5px solid #999999",
                    borderRadius: 5,
                  }}
                />
                <Tab
                  label="English"
                  {...a11yProps(1)}
                  style={{
                    minWidth: 0,
                    border: "0.5px solid #999999",
                    borderRadius: 5,
                  }}
                />
              </Tabs>
            </AppBar>
            <TabPanel
              value={languageTab}
              index={0}
              intro={
                props.clinic.description
                  ? JSON.parse(props.clinic.description).fr
                  : ""
              }
            ></TabPanel>
            <TabPanel
              value={languageTab}
              index={1}
              intro={
                props.clinic.description
                  ? JSON.parse(props.clinic.description).en
                  : ""
              }
            ></TabPanel>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

function ClinicInfo(props) {
  const classes = useStyles();
  const { clinicId, displayMessage } = useAppContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [clinic, setClinic] = useState({});
  const [openHours, setOpenHours] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [reminderData, setReminderData] = useState({});
  const [reminderType, setReminderType] = useState(0);
  const [reminderDay, setReminderDay] = useState(3);

  useEffect(() => {
    getClinicInfo();
  }, []);

  function getClinicInfo() {
    ClinicDataService.findClinicById(clinicId).then(
      (response) => {
        setClinic(response.data);
        ClinicDataService.findOpenHoursById(clinicId).then(
          (response) => {
            const tempHours = [];
            const hoursStr = response.data.en;
            const hoursArray = hoursStr.split("\n");
            hoursArray.forEach((hour) => {
              const ary = hour.split(" ");
              tempHours.push({
                day: ary[0],
                open: ary[1] + " " + ary[2].toUpperCase(),
                close: ary[4] + " " + ary[5].toUpperCase(),
              });
            });
            setOpenHours([...tempHours]);
          },
          (error) => console.log(error)
        );
        findDefaultReminders();
        ClinicDataService.findAllClinicContacts(clinicId).then(
          (response) => setContacts(response.data),
          (error) => console.log(error)
        );
        ClinicDataService.getClinicContracts(clinicId).then(
          (response) => setContracts(response.data),
          (error) => console.log(error)
        );
      },
      (error) => console.log(error)
    );
  }

  function findDefaultReminders() {
    ClinicDataService.getDefaultReminders(clinicId).then(
      (response) => {
        setReminderData(response.data);
        const tempReminders = [];
        const phoneReminders = response.data.numberOfDaysInAdvanceForPhone.split(
          "-"
        );
        phoneReminders.forEach((reminder) => {
          tempReminders.push({ type: "Phone", days: reminder });
        });
        const emailReminders = response.data.numberOfDaysInAdvanceForEmail.split(
          "-"
        );
        emailReminders.forEach((reminder) => {
          tempReminders.push({ type: "Email", days: reminder });
        });
        const smsReminders = response.data.numberOfDaysInAdvanceForTm.split(
          "-"
        );
        smsReminders.forEach((reminder) => {
          tempReminders.push({ type: "SMS", days: reminder });
        });
        setReminders([...tempReminders]);
      },
      (error) => console.log(error)
    );
  }

  function handleTabChange(event, newValue) {
    setCurrentTabIndex(newValue);
  }

  const deleteReminder = (index) => (event) => {
    let tempReminders = [...reminders];
    tempReminders.splice(index, 1);
    saveReminders(tempReminders);
  };

  function saveReminders(tempReminders) {
    let phoneReminders = tempReminders
      .filter((reminder) => reminder.type === "Phone" && reminder.days !== "")
      .map((reminder) => reminder.days)
      .join("-");
    let emailReminders = tempReminders
      .filter((reminder) => reminder.type === "Email" && reminder.days !== "")
      .map((reminder) => reminder.days)
      .join("-");
    let smsReminders = tempReminders
      .filter((reminder) => reminder.type === "SMS" && reminder.days !== "")
      .map((reminder) => reminder.days)
      .join("-");
    ClinicDataService.updateDefaultReminders({
      ...reminderData,
      numberOfDaysInAdvanceForPhone: phoneReminders,
      numberOfDaysInAdvanceForEmail: emailReminders,
      numberOfDaysInAdvanceForTm: smsReminders,
    }).then(
      (response) => {
        findDefaultReminders();
        displayMessage("The default reminders have been successfully updated");
      },
      (error) => console.log(error)
    );
  }

  function addReminder() {
    let tempReminders = [...reminders];
    if (!reminderDay) {
      window.alert("Please enter the reminder day");
      return;
    }
    switch (reminderType) {
      case 0:
        tempReminders.push({ type: "Phone", days: reminderDay });
        break;
      case 1:
        tempReminders.push({ type: "Email", days: reminderDay });
        break;
      case 2:
        tempReminders.push({ type: "SMS", days: reminderDay });
        break;
      default:
        console.log("no reminder is add!!!");
    }
    saveReminders(tempReminders);
  }

  const changeReminderType = (event) => {
    setReminderType(event.target.value);
  };

  const changeReminderDay = (event) => {
    setReminderDay(event.target.value);
  };

  // Menu section starts
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //Menu section ends

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {/* <Grid container style={{ backgroundColor: '#F0F4FA' }}>
          <Grid item md={8}> */}
        {/* <FormControl className={classes.formControl}>
              <InputLabel className={classes.inputLabel} id="category-label">Doctor</InputLabel>
              <Select
                id="category"
                name="category"
                label="Category"
                variant="outlined"
                value={"00"}
              >
                <MenuItem key="1" value="1">
                  Doctor name
              </MenuItem>
              </Select>
            </FormControl> */}

        {/* <Button classes={{ root: classes.borderRadius, }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              endIcon={<ArrowDropDownSharpIcon />}
              onClick={handleClick}>
              Doctor
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Doctor</MenuItem>
              <MenuItem onClick={handleClose}>Patient</MenuItem>
              <MenuItem onClick={handleClose}>Clinic</MenuItem>
            </Menu>

            <div className={classes.search}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.searchIcon}>
              <SchIcon />
            </div> */}
        {/* <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Search</InputLabel>
            <OutlinedInput
              id="searchBox"
              name="searchBox"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl> */}

        {/* </Grid> */}

        {/* </Grid> */}
        <SearchBar name="clinic" openNewIssue={props.openNewIssue} />
        <Divider />
        <Container>
          <Grid container style={{ marginTop: 50, height: 200 }} spacing={1}>
            <Grid item md={4} style={{ height: "100%" }}>
              <Card style={{ height: "100%" }}>
                <CardContent>
                  <Image src={clinic.photoUrl} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={7} style={{ height: "100%" }}>
              <Card style={{ height: "100%" }}>
                <CardContent style={{ height: "100%", marginTop: 20 }}>
                  <Grid container spacing={3}>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography>Name</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography>{clinic.name}</Typography>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography>ID</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography>{clinic.id}</Typography>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography>Address</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography>
                        {clinic.apartment ? clinic.apartment + "-" : ""}
                        {clinic.streetNumber +
                          " " +
                          clinic.streetName +
                          ", " +
                          clinic.city +
                          " " +
                          clinic.provinceId +
                          " " +
                          clinic.country +
                          " " +
                          clinic.postCode}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <AppBar
            position="static"
            color="default"
            style={{ marginTop: 35, width: "92%" }}
          >
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Introduction" {...a11yProps(0)} />
              <Tab label="Open hour" {...a11yProps(1)} />
              <Tab label="Contact" {...a11yProps(2)} />
              <Tab label="Reminder Setting" {...a11yProps(3)} />
              <Tab label="Contract" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <IntroPanel
            value={currentTabIndex}
            index={0}
            clinic={clinic}
          ></IntroPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={1}
            style={{ width: "92%" }}
          >
            <Table style={{ marginLeft: "auto", marginRight: "auto" }}>
              <TableBody>
                {openHours.map((hour, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{hour.day}</TableCell>
                    <TableCell align="center" style={{ color: "#1A568E" }}>
                      {hour.open}
                    </TableCell>
                    <TableCell align="center" style={{ color: "#1A568E" }}>
                      {hour.close}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={2}
            style={{ width: "92%" }}
          >
            <form>
              {contacts.map((contact, index) => (
                <React.Fragment>
                  {1 == index % 2 ? (
                    <Divider style={{ marginTop: 30, marginBottom: 30 }} />
                  ) : (
                    ""
                  )}
                  <Grid container spacing={5} key={contact.id}>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Name</InputLabel>
                        <Input disableUnderline={true} value={contact.name} />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Email</InputLabel>
                        <Input disableUnderline={true} value={contact.email} />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Department</InputLabel>
                        <Input
                          disableUnderline={true}
                          value={contact.department}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Phone</InputLabel>
                        <Input disableUnderline={true} value={contact.phone} />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl>
                        <InputLabel>Fax</InputLabel>
                        <Input disableUnderline={true} value={contact.fax} />
                      </FormControl>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </form>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={3}
            style={{ width: "92%" }}
          >
            <Table>
              <TableBody>
                {reminders.map((reminder, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="right"
                      style={{
                        font: "normal normal 600 16px/9px Montserrat",
                        color: "#1A568E",
                      }}
                    >
                      {reminder.type.toUpperCase() == "PHONE" ? (
                        <PhoneCallIcon />
                      ) : reminder.type.toUpperCase() == "EMAIL" ? (
                        <EmailIcon />
                      ) : (
                        <PhoneIcon />
                      )}
                      &nbsp; {reminder.type}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        disableUnderline={true}
                        style={{
                          border: "0.5px solid #707070",
                          borderRadius: 5,
                        }}
                        InputProps={{
                          className: classes.centerInput,
                        }}
                        value={reminder.days}
                        variant="filled"
                        className={classes.days}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#1A568E", textAlign: "left" }}
                    >
                      day(s) before appointment
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={deleteReminder(index)}>
                        <DeleteForeverOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        className="selected-item"
                        value={reminderType}
                        displayEmpty
                        width="fullWidth"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        onChange={changeReminderType}
                      >
                        <MenuItem value={0} className="icon-item">
                          <PhoneInTalkIcon />
                          <span>Phone</span>
                        </MenuItem>
                        <MenuItem value={1} className="icon-item">
                          <MailOutlineIcon />
                          <span>Email</span>
                        </MenuItem>
                        <MenuItem value={2} className="icon-item">
                          <SmsIcon />
                          <span>SMS</span>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={reminderDay}
                      onChange={changeReminderDay}
                      style={{
                        border: "0.5px solid #707070",
                        borderRadius: 5,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "#1A568E", textAlign: "left" }}
                  >
                    day(s) before appointment
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addReminder}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabCardPanel>
          <TabCardPanel
            value={currentTabIndex}
            index={4}
            style={{ width: "92%" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Contract ID</TableCell>
                  <TableCell>Sign Date</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts.map((contract) => (
                  <TableRow key={contract.id}>
                    <TableCell>{contract.contractNo}</TableCell>
                    <TableCell>{contract.signingDate}</TableCell>
                    <TableCell>{contract.startDate}</TableCell>
                    <TableCell>{contract.endDate}</TableCell>
                    <TableCell>{contract.uploadDate}</TableCell>
                    <TableCell>
                      <Button
                        style={{
                          backgroundColor: "#3553B21A",
                          border: "1px solid #3553B2",
                          borderRadius: 5,
                          color: "#3553B2",
                          minWidth: 80,
                          textAlign: "center",
                          padding: 5,
                        }}
                        href={
                          contract.attachment
                            ? contract.attachment.attachmentLocation
                            : ""
                        }
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabCardPanel>
          <div
            style={{
              width: "70px",
              height: "70px",
              backgroundColor: "#569DF0CB",
              boxShadow: "5px 5px 30px #388EF280",
              borderRadius: "50%",
              position: "absolute",
              bottom: "6%",
              right: "6%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20%",
            }}
          >
            <PencileIcon
              style={{
                width: "50%",
                height: "50%",
                display: "inline",
                marginTop: 18,
                marginLeft: 17,
              }}
            />
          </div>

          <div
            style={{
              width: "70px",
              height: "70px",
              backgroundColor: "#569DF0CB",
              boxShadow: "5px 5px 30px #388EF280",
              borderRadius: "50%",
              position: "absolute",
              bottom: "12%",
              right: "6%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20%",
            }}
          >
            <PencileIcon
              style={{
                width: "50%",
                height: "50%",
                display: "inline",
                marginTop: 18,
                marginLeft: 17,
              }}
            />
          </div>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default ClinicInfo;
