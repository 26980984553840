import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import {
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Button,
  // Container,
  // Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from "../services/AuthService";
import "./LoginStyle.css";
import { ReactComponent as LogoLoginIcon } from "../static/images/SVGs/logoLogin.svg";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"cpyright © "}
//       <Link color="inherit" href="http://medinetwork.surge.sh">
//         Medinetwork
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    AuthService.login(details.email, details.password).then((response) => {
      if (
        response.data.status === "LOGGED_IN" ||
        response.data.status === "FORCE_CREATE_PROFILE"
      ) {
        AuthService.setCredentials(response.data);
        props.history.push("/");
      } else if (
        response.data.status === "NO_SUCH_USER"
      ) {
        window.alert("Uesername or Password error.");
      }
    });
  };

  return (
    <div className="login-container">
      <CssBaseline />
      <div className="left-side d-none d-sm-block">
        <div className="icon-line">
          <LogoLoginIcon />
        </div>
        <div className="image-content">
          <img src="/assets/images/loginContent.svg" alt="login content" />
        </div>
      </div>
      <div className="right-side">
        <div className="paper">
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          {/* <Typography component="h1" variant="h5">
            Sign in 
          </Typography> */}
          <form className="form" noValidate onSubmit={handleSubmit}>
            <div className="form-title">Login</div>            
            <Input              
              required
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              placeholder="Email Address"
              autoFocus
              onChange={handleChange}
            />
            {/* <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            /> */}
            <Input
              required
              name="password"
              label="Password"
              className="password-input"
              type={values.showPassword ? "text" : "password"}
              id="password"
              placeholder="password"
              autoComplete="current-password"
              onChange={handleChange}
              endAdornment={
              <InputAdornment position="end" className="password-icon-area">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
        }
            />
            {/* <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            /> */}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="submit"
            >
              Sign In
            </Button>
            <div className="login-actions">
              {/* <div className="remember-me">
                <input type="checkbox" value="remember" name="remember"/>
                <label for="remember">Remember me</label>
              </div> */}
              <FormControlLabel
                className="remember-me"
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Link
                href="#"
                variant="body2"
                className="forget-psw d-flex align-items-center"
              >
                Forgot password?
              </Link>
            </div>
            {/* <div className="sign-up">
              <Link href="#" variant="body2">
                <span className="sign-up-label">Don't have an account?</span>
                <span className="sign-up-link">Sign Up</span>
              </Link>
            </div> */}
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </form>
        </div>
      </div>
      {/* <Box mt={8}>
        <Copyright />
      </Box> */}
    </div>
  );
}
