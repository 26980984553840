import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard(props) {
  return (
    <Grid container spacing={3}>
      <Grid item md={4}></Grid>
      <Grid item md={4}>
        <Typography>Welcome to XXX Clinic</Typography>
      </Grid>
      <Grid item md={4}></Grid>
      <Grid item md={6}>
        <Card>
          <CardContent>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Button variant="outlined" size="large" component={RouterLink} to="/appointment"
               onClick={() => props.setTitle(localStorage.getItem("clinicName"))}>
                Appointment
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardContent>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Button variant="outlined" size="large" component={RouterLink} to="/doctors"
               onClick={() => props.setTitle(localStorage.getItem("clinicName"))}>
                Doctors
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardContent>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Button variant="outlined" size="large" component={RouterLink} to="/patients"
               onClick={() => props.setTitle(localStorage.getItem("clinicName"))}>
                Patients
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardContent>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Button variant="outlined" size="large" component={RouterLink} to="/clinic"
               onClick={() => props.setTitle(localStorage.getItem("clinicName"))}>
                Clinic
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardContent>
            <Box alignItems="center" justifyContent="center" display="flex">
              <Button variant="outlined" size="large" component={RouterLink} to="/survey"
               onClick={() => props.setTitle(localStorage.getItem("clinicName"))}>
                Survey
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
