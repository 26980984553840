import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PeopleIcon from '@material-ui/icons/People';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import SettingsIcon from '@material-ui/icons/Settings';
import EventIcon from '@material-ui/icons/Event';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { ReactComponent as Aptm } from "../static/images/SVGs/aptm.svg";
import { ReactComponent as Dctr } from "../static/images/SVGs/dctr.svg";
import { ReactComponent as Ptnt } from "../static/images/SVGs/ptnt.svg";
import { ReactComponent as Clinic } from "../static/images/SVGs/clinic.svg";
import { ReactComponent as Srv } from "../static/images/SVGs/srv.svg";
import { ReactComponent as QckMsg } from "../static/images/SVGs/quick-message.svg";
import { ReactComponent as Logo } from "../static/images/SVGs/logo.svg";
import { createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { Close } from "@material-ui/icons";
import ClinicDataService from "../services/ClinicDataService";
import AuthService from "../services/AuthService";
import './ListItemsStyle.css';

const theme = createMuiTheme({
  typography: {
    body1: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      fontSize: 14,
      color: "white"
    }
  }
})

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#3553b2',
    width: '100%',
    height: '100%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  disabledItem: {
    height: 50,
    padding: '2em 4em 2em 2em',
    textAlign: 'left',
    textTransform: 'uppercase',
    
  },
  sidebarItem: {
    height: 50,
    padding: '2em 4em 2em 2em',
    textAlign: 'left',
    textTransform: 'uppercase',
    '&:hover': {
      background: "#264198",
      borderLeft: '5px solid #23D8F8'
    },
  },
  sidebarItemHighlight: {
    height: 50,
    padding: '2em 4em 2em 2em',
    textAlign: 'left',
    textTransform: 'uppercase',
    background: "#264198",
    borderLeft: '5px solid #23D8F8'
  },
  sidebarItemText: {
    fontStyle: 'normal',
    textAlign: 'left',
  },
  sidebarDisabledItemText: {
    '& span': {
      color: 'gray',
    },
    textAlign: 'left',
  },
  svg: {
    width: '14%',
    margin: '0 12% 0 0'
  },
  msg_svg: {
    height: 50,
    width: '65%',
    marginTop: 50,
    marginLeft: '2em',
    paddingLeft: 0,
    opacity: 1
  },
  divider: {
    background: '#fff',
    opacity: 0.3,
    height: 3,
    marginTop: 50,
    width: '60%',
    marginLeft: '2em'
  },
  list: {
    height: '100%'
  },
  copyright: {
    textAlign: 'center',
    width: '100%',
    color: '#dee7f6',
    fontSize: 10,
    position: 'absolute',
    bottom: '20px'
  },
  logo: {
    width: '55%',
    padding: "10% 0 10% 20%",
  }

}));

export default function MainListItems(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState('appointment');

  const handleMenuItemClick = (item) => {
    if (AuthService.getClinicId() != null) {
      props.setTitle(localStorage.getItem("clinicName"))
      setActiveItem(item);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSend = (e) => {
    e.preventDefault();
    state.type = type;
    state.language = lang;
    state.emailAddress = email;
    state.emailTitle = emailTitle;
    state.phone = phone;
    state.message = message;
    let res = message.replace(new RegExp("\n", "gm"), "<br />");
    res = res.replace(new RegExp("\r", "gm"), "<br />");
    state.htmlContent = res;

    // post data, make request for api
    console.log("state ->", state)
    ClinicDataService.sendQuickMsg(state).then(
      (response) => {
        if (response.status === 202) {
          setOpen(false);
          //reset form data
          state.type = "1";
          state.language = "en";
          state.emailAddress = "";
          state.emailTitle = "";
          state.phone = "";
          state.message = "";
          state.htmlContent = "";

          //reset dialog
          clearDialog();

          // popup success alert
          window.alert('Send quick message successfully!')
        }else {
          // popup fail alert
          window.alert('Send quick message failed!')
        }
      },
      (error) => {
        console.log(error);
      })
  };

  const HTMLEncode = (str) => {
    const temp = document.createElement("div"); 
    temp.innerHTML = message; 
  }

  const clearDialog = () => {
    //reset component
    setType("1");
    setPhone("");
    setLang("en");
    setEmail("");
    setEmailTitle("");
    setMessage("");
  }

  const handleClose = () => {
    //reset dialog
    clearDialog();

    setOpen(false);
  };

  const [type, setType] = React.useState('1');

  const handleSelectChange = (event) => {
    console.log("type before ->", type)
    setType(event.target.value);
    console.log("type after->", type)
  }

  const [phone, setPhone] = React.useState('');
  
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  }

  const [emailTitle, setEmailTitle] = React.useState('');
  
  const handleEmailTitleChange = (event) => {
    setEmailTitle(event.target.value);
  }

  const [email, setEmail] = React.useState('');
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }
  
  const [lang, setLang] = React.useState('en');
  
  const handleLangChange = (event) => {
    setLang(event.target.value);
  }
  
  const [message, setMessage] = React.useState('');
  
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const handlePopupSubmit = (e) => {
    e.preventDefault();
    ClinicDataService.sendQuickMsg(state).then((Response) => {

    })
    setOpen(false);
  };

  let state = {
    language: '',
    type: '',
    phone: '',
    emailAddress: '',
    emailTitle: '',
    message: '',
    htmlContent: ''
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {/* <ListItem button component={RouterLink} to='/dashboard'>
	  <ListItemIcon>
	    <DashboardIcon />
	  </ListItemIcon>
	  <ListItemText primary="Dashboard" />
	</ListItem> */}
        <ListItem className={classes.logo} button component={RouterLink} to=""
          onClick={() => props.setTitle(localStorage.getItem("clinicName"))}>
          <Logo />
        </ListItem>
        <ListItem className={AuthService.getClinicId() == null ? classes.disabledItem : activeItem === 'appointment' ? classes.sidebarItemHighlight : classes.sidebarItem} button disabled={AuthService.getClinicId() == null} component={RouterLink} to="/appointment"
          onClick={() => handleMenuItemClick('appointment')}>
          <Aptm className={classes.svg} />
          <ListItemText className={AuthService.getClinicId() == null ? classes.sidebarDisabledItemText : classes.sidebarItemText} primary="Appointment" />
        </ListItem>
        <ListItem className={AuthService.getClinicId() == null ? classes.disabledItem : activeItem === 'doctor' ? classes.sidebarItemHighlight : classes.sidebarItem} button disabled={AuthService.getClinicId() == null} component={RouterLink} to="/doctors"
          onClick={() => handleMenuItemClick('doctor')}>
          <Dctr className={classes.svg} />
          <ListItemText className={AuthService.getClinicId() == null ? classes.sidebarDisabledItemText : classes.sidebarItemText} primary="Doctor" />
        </ListItem>
        <ListItem className={AuthService.getClinicId() == null ? classes.disabledItem : activeItem === 'patient' ? classes.sidebarItemHighlight : classes.sidebarItem} button disabled={AuthService.getClinicId() == null} component={RouterLink} to="/patients"
          onClick={() => handleMenuItemClick('patient')}>
          <Ptnt className={classes.svg} />
          <ListItemText className={AuthService.getClinicId() == null ? classes.sidebarDisabledItemText : classes.sidebarItemText} primary="Patient" />
        </ListItem>
        <ListItem className={AuthService.getClinicId() == null ? classes.disabledItem : activeItem === 'clinic' ? classes.sidebarItemHighlight : classes.sidebarItem} button disabled={AuthService.getClinicId() == null} component={RouterLink} to="/clinic"
          onClick={() => handleMenuItemClick('clinic')}>
          <Clinic className={classes.svg} />
          <ListItemText className={AuthService.getClinicId() == null ? classes.sidebarDisabledItemText : classes.sidebarItemText} primary="Clinic" />
        </ListItem>
        <ListItem className={AuthService.getClinicId() == null ? classes.disabledItem : activeItem === 'survey' ? classes.sidebarItemHighlight : classes.sidebarItem} button disabled={AuthService.getClinicId() == null} component={RouterLink} to="/survey"
          onClick={() => handleMenuItemClick('survey')}>
          <Srv className={classes.svg} />
          <ListItemText className={AuthService.getClinicId() == null ? classes.sidebarDisabledItemText : classes.sidebarItemText} primary="Survey" />
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem className={classes.msg_svg} button onClick={handleClickOpen}>
          <QckMsg />
        </ListItem>
        <Dialog 
          open={open}  
          aria-labelledby="alert-dialog-title" 
          aria-describedby="alert-dialog-description"
          className="quick-popup"
          PaperProps={{
            style: {
              backgroundColor: '#3056D0E6',
              boxShadow: 'none',
            },
          }}>
          <DialogTitle className="dialog-title">
            <IconButton
              aria-label="close"
              onClick={handleClose} 
              className="close-icon-btn"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              > 
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <form className="popup-content" onSubmit={handleSend}>
              <FormControl className="form-item">
                <label>Type</label>
                <NativeSelect
                   defaultValue={1}
                   onChange={handleSelectChange}
                   className="type-select"
                   inputProps={{
                     name: 'type',
                     id: 'uncontrolled-native',
                   }}
                 >
                  <option value={1}>SMS</option>
                  <option value={2}>EMAIL</option>
                  <option value={3}>VOICE MSG</option>
                </NativeSelect>
              </FormControl>
              <FormControl className={
                "form-item" +
                (type==="2" ? " hidden" : "")
                }>
                <label>Phone</label>
                <Input className="type-select" 
                  defaultValue="xxxxxxxxxx" onChange={handlePhoneChange}
                  value={phone} type="number"
                  autoComplete="off"
                  inputProps={{
                     name: 'phone',
                    //  id: 'uncontrolled-native',
                   }}
                />
              </FormControl>
              <FormControl component="fieldset" className={
                "form-item" +
                (type==="1" || type ==="2" ? " hidden" : "")
                }>
                {/* <FormLabel >labelPlacement</FormLabel> */}
                <label>Language</label>
                <RadioGroup row aria-label="position" name="lang" defaultValue="en" 
                  className="radio-group"
                  onChange={handleLangChange}>
                  <FormControlLabel className="radio-item" value="fr" control={<Radio color="white" />} label="French"/>
                  <FormControlLabel className="radio-item" value="en" control={<Radio color="white" />} label="English"/>
                </RadioGroup>
              </FormControl>
              <FormControl className={
                "form-item" +
                (type==="1" || type ==="3" ? " hidden" : "")
                }>
                <label>Email Address</label>
                <Input className="type-select" onChange={handleEmailChange}
                  value={email} type="email"
                  autoComplete="off"
                  inputProps={{
                     name: 'email'
                   }}
                />
              </FormControl>
              <FormControl className={
                "form-item" +
                (type==="1" || type ==="3" ? " hidden" : "")
                }>
                <label>Email Title</label>
                <Input className="type-select" onChange={handleEmailTitleChange}
                  value={emailTitle} type="text"
                  autoComplete="off"
                  inputProps={{
                     name: 'emailTitle'
                   }}
                />
              </FormControl>
              <FormControl className="comment-area">
                <label>Message</label>
                <TextField
                  id="standard-multiline-static"
                  className="comment-field"
                  multiline
                  onChange={handleMessageChange}
                  rows={4}
                  defaultValue=""
                  variant="standard"
                  value={message}
                  inputProps={{
                     name: 'message'
                   }}
                />
              </FormControl>
                <Button className="form-btn form-submit-btn" type="submit">
                  Send
                </Button>
            </form>
          </DialogContent>
        </Dialog>
        <div className={classes.copyright}>
          © 2020 by ClinicPlus.
  <br />
  All Rights Reserved.
  </div>
      </div>
    </ThemeProvider>
  );
}
export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
