import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import ClinicInfo from "./ClinicInfo";
import ClinicIssue from "./ClinicIssue";

function Clinic() {
  const [newIssue, setNewIssue] = useState(false);

  function openNewIssue() {
    setNewIssue(true);
  }

  function closeNewIssue() {
    setNewIssue(false);
  }

  return (
    <Box>
      {newIssue ? (
        <ClinicIssue closeNewIssue={closeNewIssue} />
      ) : (
        <ClinicInfo openNewIssue={openNewIssue} />
      )}
    </Box>
  );
}

export default Clinic;
