import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Container,
  Divider,
  InputLabel,
  FormControl,
  Input,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClinicDataService from "../services/ClinicDataService";
import { useAppContext } from "../services/authContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function ClinicIssue(props) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const { displayMessage, clinicId } = useAppContext();

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  function saveIssue() {
    ClinicDataService.createIssue([
      {
        id: 0,
        mobileId: "web",
        createTime: new Date().toISOString(),
        clinicId: clinicId,
        count: 0,
        type: "webissue",
        message: message,
      },
    ]).then(
      (reponse) => displayMessage("The issue has been successfully reported."),
      (error) => console.log(error)
    );
  }

  return (
    <React.Fragment>
      <Typography>
        <Button
          variant="outlined"
          onClick={props.closeNewIssue}
          color="primary"
          style={{ marginLeft: 50 }}
        >
          Back
        </Button>
      </Typography>
      <Container style={{ margin: 60 }}>
        <Typography variant="h5">Report An Issue</Typography>
        <Divider style={{ marginTop: 30, marginBottom: 30 }} />
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={10}>
            <FormControl fullWidth>
              <Typography>Description of the issue</Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={8}
                variant="outlined"
                fullWidth
                value={message}
                onChange={handleMessageChange}
              />
            </FormControl>
            <FormControl style={{ marginTop: 50 }}>
              <Typography>Screenshot (Optional)</Typography>
              <TextField type="file" hidden />
            </FormControl>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
        <Typography align="center" style={{ marginTop: 50 }}>
          <Button variant="contained" style={{ margin: 10 }}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={saveIssue}
          >
            Save
          </Button>
        </Typography>
        <Divider style={{ marginTop: 30, marginBottom: 30 }} />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Accordion 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </React.Fragment>
  );
}

export default ClinicIssue;
