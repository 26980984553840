import axios from 'axios'
import {GET, POST} from "../services/AuthService"
import AuthService from "../services/AuthService"
export const USERNAME = "userName";
export const ROLE = "role";
export const LOGIN_TIME = "loginTime";

// axios instance for making requests 
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  let username = localStorage.getItem(USERNAME);
  let loginTime = localStorage.getItem(LOGIN_TIME);

  if (null === username || null ===loginTime) {
    localStorage.clear();
    return false;
  }

  const timeDiff = Date.now() - loginTime;
  // expired when time more than 23hours 55minutes  
  const isExpired = timeDiff > 86100000;
  if (isExpired) {
    window.alert("Token is expired, please login again.");
    localStorage.clear();
    // eslint-disable-next-line no-restricted-globals
    const origin = location.origin;
    // eslint-disable-next-line no-restricted-globals
    location.replace(`${origin}/login`)
  }

  // add token to request headers
  config.headers.authorization = localStorage.getItem('token');
  return config;
});

export default axiosInstance;