// import axios from "axios";
import axios from "../api/axios";
import { BASE_URL } from "./AuthService";

class ClinicDataService {
  findAllClinicCategories(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/categories`);
  }

  findDoctorsByClinicId(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/doctors`);
  }

  findAllAdminClinics(accountId) {
    return axios.get(`${BASE_URL}/admin/clinics/${accountId}/allClinics`);
  }

  findClinicById(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}`);
  }

  findOpenHoursById(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/openHours`);
  }

  findAllClinicContacts(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/contacts`);
  }

  getClinicContracts(clinicId) {
    return axios.get(`${BASE_URL}/clinics/${clinicId}/allContracts`);
  }

  getDefaultReminders(clinicId) {
    return axios.get(`${BASE_URL}/ClinicReminder/${clinicId}`);
  }

  updateDefaultReminders(reminderData) {
    return axios.post(`${BASE_URL}/ClinicReminder/save`, reminderData);
  }

  findAllCategories() {
    return axios.get(`${BASE_URL}/categories`);
  }

  findCategoryTypes(categoryId) {
    return axios.get(`${BASE_URL}/categories/${categoryId}/types`);
  }

  createIssue(issue) {
    return axios.post(`${BASE_URL}/feedback/messages`, issue);
  }

  sendQuickMsg(msgInfo) {
    console.log("msgInfo", msgInfo);
    let subPath = "";
    let data = {};
    switch (msgInfo.type) {
      case "1":
        subPath = "sms";
        data = {
          originNumber: "+12049000519",
          mobileNumber: "+1" + msgInfo.phone,
          message: msgInfo.message,
        };
        break;
      case "2":
        subPath = "email";
        data = {
          from: "contactus@cliniplus.net",
          to: [`${msgInfo.emailAddress}`],
          subject: msgInfo.emailTitle,
          plainContent: msgInfo.htmlContent,
          htmlContent: msgInfo.message,
        };
        break;
      case "3":
        subPath = "voice";
        data = {
          lang: msgInfo.language,
          name: "",
          phoneNumber: "+1" + msgInfo.phone,
          message: msgInfo.message,
        };
        break;
      default:
        subPath = "voice";
        break;
    }
    console.log("data for send: ", data);
    return axios.post(`${BASE_URL}/${subPath}`, data);
  }
}

export default new ClinicDataService();
