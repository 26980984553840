import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Avatar,
  Typography,
  Button,
} from "@material-ui/core";

function AccountSetting(props) {
  const [uploadFile, setUploadFile] = useState("");

  function handleFileChange(event) {
    setUploadFile(event.target.files[0]);
  }

  function back2Default(event) {
    props.history.push("/");
  }

  return (
    <React.Fragment>
      <Typography>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 50 }}
          onClick={back2Default}
        >
          Back
        </Button>
      </Typography>
      <Container>
        <Grid container className="add-info-section">
          <Grid item md={6} className="add-info-left">
            <FormControl className="left-content">
              <Avatar src="/static/images/clinic.svg" className="big-icon" />
              <TextField type="file" onChange={handleFileChange} />
            </FormControl>
            <Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 150 }}
              >
                Upload
              </Button>
            </Typography>
          </Grid>
          <Grid item md={6} className="add-info-right">
            <Grid container spacing={3}>
              <Grid item md={4}>
                <FormLabel>First name</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Middle name</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Last name</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Phone</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Language</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Old password</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={4}>
                <FormLabel>New password</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={4}>
                <FormLabel>Confirm Password</FormLabel>
              </Grid>
              <Grid item md={8}>
                <TextField variant="outlined" size="small" />
              </Grid>
              <Grid item md={3}>
                <Button variant="contained">Cancel</Button>
              </Grid>
              <Grid item md={3}>
                <Button variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
              <Grid item md={6}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default AccountSetting;
