import React, { useState, useEffect } from "react";
import SurveyList from "./SurveyList";
import SurveyDetail from "./SurveyDetail";
import SurveyDataService from "../services/SurveyDataService";
import { useAppContext } from "../services/authContext";

function SurveysInfo(props) {
  const { displayMessage } = useAppContext();
  const [showDetails, setShowDetails] = useState(false);
  const { clinicId } = useAppContext();
  const [openDesigner, setOpenDesigner] = useState(false);
  const [survey, setSurvey] = useState({ survey: {} });
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState("0");
  const initialQuestion = {
    type: 1,
    context: "",
    context_fr: "",
    mandatory: false,
    correct: false,
    options: [],
  };
  const [questions, setQuestions] = useState([]);
  const [designedQuestion, setDesignedQuestion] = useState(initialQuestion);
  const [questionEditing, setQuestionEditing] = useState(true);
  const [viewOnly, setViewOnly] = useState(false);

  function showSurveyList() {
    setShowDetails(false);
  }

  const displayDetails = (surveyId, view) => (event) => {
    setViewOnly(view);
    SurveyDataService.findSurveyById(surveyId).then(
      (response) => {
        //setDesignedQuestion(response.data.survey);
        let tempQuestions = [];
        response.data.surveyQuestions.forEach((question) => {
          let tempOptions = JSON.parse(question.options);
          let tempContext = JSON.parse(question.context);
          tempQuestions.push({
            ...question,
            context: tempContext.en,
            context_fr: tempContext.fr,
            options: tempOptions,
          });
        });
        setQuestions([...tempQuestions]);
        setSurvey(response.data);
        setTitle(response.data.survey.title ? response.data.survey.title : "");
      },
      (error) => console.log(error)
    );
    setShowDetails(true);
  };

  const handleQuestionEdit = (index) => (event) => {
    setDesignedQuestion(JSON.parse(JSON.stringify(questions[index])));
    setQuestionEditing(true);
    setOpenDesigner(true);
  };

  const handleQuestionDelete = (index) => (event) => {
    if (window.confirm("Do you really want to delete this question?")) {
      let tempQuestions = questions;
      tempQuestions.splice(index, 1);
      setQuestions([...tempQuestions]);
    }
  };

  function handleSurveyDialogClose() {
    setTitle("");
    setId("");
    setDesignedQuestion(initialQuestion);
    setQuestions([]);
    props.handleSurveyClose(false);
  }

  function openQuestionDesigner() {
    setDesignedQuestion(initialQuestion);
    setQuestionEditing(false);
    setOpenDesigner(true);
  }

  function closeQuestionDesigner() {
    setOpenDesigner(false);
  }

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function updateTitle() {
    let tempSurvey = { ...survey.survey, title: title };
    SurveyDataService.updateSurveyTitle(tempSurvey).then(
      (response) => {
        props.populateSurveys(props.page, props.rowsPerPage);
        displayMessage(
          "The survey title has already been successfully updated!"
        );
      },
      (error) => console.log(error)
    );
  }

  const handleMandatoryChange = (name) => (event) => {
    setDesignedQuestion({ ...designedQuestion, [name]: event.target.checked });
  };

  const handleTextChange = (name) => (event) => {
    setDesignedQuestion({ ...designedQuestion, [name]: event.target.value });
  };

  const handleTypeChange = (event) => {
    setDesignedQuestion({ ...designedQuestion, type: event.target.value });
  };

  const handleAddOption = () => {
    let tempQuestion = designedQuestion;
    tempQuestion.options = [
      ...tempQuestion.options,
      { en: "", fr: "", correctAnswer: false },
    ];
    setDesignedQuestion({ ...tempQuestion });
  };

  const handleCorrectAnswer = (index) => (event) => {
    let tempQuestion = designedQuestion;
    if (tempQuestion.type === 2) {
      tempQuestion.options.forEach((option) => (option.correctAnswer = false));
    }
    tempQuestion.options[index].correctAnswer = event.target.checked;
    setDesignedQuestion({ ...tempQuestion });
  };

  const handleDeleteOption = (index) => (event) => {
    let tempQuestion = designedQuestion;
    tempQuestion.options.splice(index, 1);
    setDesignedQuestion({ ...tempQuestion });
  };

  const handleOptionChanged = (name, index) => (event) => {
    let tempQuestion = JSON.parse(JSON.stringify(designedQuestion));
    tempQuestion.options[index][name] = event.target.value;
    setDesignedQuestion({ ...tempQuestion });
  };

  const saveDesignedQuestion = () => {
    if (designedQuestion.id) {
      let optionsStr = "[";
      if (designedQuestion.options) {
        let begin = 0;
        designedQuestion.options.map((element) => {
          if (0 === begin) {
            begin = 2;
          } else {
            optionsStr += ",";
          }
          optionsStr += '{"en": "' + element.en + '"';
          optionsStr += ',"fr": "' + element.fr + '"';
          optionsStr +=
            ',"correctAnswer": ' +
            (element.correctAnswer ? element.correctAnswer : false) +
            " }";
        });
      }
      optionsStr += "]";

      let tempQuestion = {
        ...designedQuestion,
        context:
          '{"en":"' +
          designedQuestion.context +
          '","fr":"' +
          designedQuestion.context_fr +
          '"}',
        options: optionsStr,
      };
      SurveyDataService.updateQuestion(tempQuestion).then(
        (response) => {
          questions.forEach((question, index) => {
            if (question.id === designedQuestion.id) {
              questions[index] = designedQuestion;
            }
          });
          displayMessage("The survey has been successfully updated!");
        },
        (error) => console.log(error)
      );
    } else {
      setQuestions([...questions, designedQuestion]);
    }
    setDesignedQuestion(initialQuestion);
    closeQuestionDesigner();
  };

  const publishSurvey = () => {
    setStatus("PUBLISH");
    //createSurvey();

    SurveyDataService.changeSurveyStatus(id, "PUBLISH").then((repsones) => {
      props.handSurveyclose(true);
    });
  };

  const saveSurvey = () => {
    if (status === "PUBLISH") {
      setId(null);
      setStatus("DRAFT");
    }
    createSurvey();
  };

  const createSurvey = () => {
    let formattedQuestions = [];
    questions.map((question) => {
      let optionsStr = "[";
      if (question.options) {
        let begin = 0;
        question.options.map((element) => {
          if (0 === begin) {
            begin = 2;
          } else {
            optionsStr += ",";
          }
          optionsStr += '{"en": "' + element.en + '"';
          optionsStr += ',"fr": "' + element.fr + '"';
          optionsStr +=
            ',"correctAnswer": ' +
            (element.correctAnswer ? element.correctAnswer : false) +
            " }";
        });
      }
      optionsStr += "]";
      formattedQuestions.push({
        creatorId: 22,
        context:
          '{"en":"' +
          question.context +
          '","fr":"' +
          question.context_fr +
          '"}',
        type: question.type,
        mandatory: question.mandatory,
        options: optionsStr,
      });
      console.log(optionsStr);
    });
    let tempSurvey = {
      tempSurvey: {
        creatorId: "22",
        operatorId: null,
        clinicId: clinicId,
        title: title,
        status: status,
        id: id,
      },
      surveyQuestions: [...formattedQuestions],
    };
    SurveyDataService.createSurvey(tempSurvey).then((response) => {
      props.handleSurveyClose(true);
      console.log(response);
    });
  };

  return (
    <React.Fragment>
      {showDetails ? (
        <SurveyDetail
          showSurveyList={showSurveyList}
          survey={survey}
          handleQuestionDelete={handleQuestionDelete}
          handleQuestionEdit={handleQuestionEdit}
          questions={questions}
          question={designedQuestion}
          open={openDesigner}
          closeDesigner={closeQuestionDesigner}
          saveDesignedQuestion={saveDesignedQuestion}
          handleMandatoryChange={handleMandatoryChange}
          handleTextChange={handleTextChange}
          handleOptionChanged={handleOptionChanged}
          handleTypeChange={handleTypeChange}
          handleAddOption={handleAddOption}
          handleCorrectAnswer={handleCorrectAnswer}
          handleDeleteOption={handleDeleteOption}
          title={title}
          handleTitleChange={handleTitleChange}
          updateTitle={updateTitle}
          viewOnly={viewOnly}
          setQuestions={setQuestions}
        />
      ) : (
        <SurveyList
          openNewSurvey={props.openNewSurvey}
          surveyList={props.surveyList}
          displayDetails={displayDetails}
          populateSurveys={props.populateSurveys}
          totalElement={props.totalElement}
          page={props.page}
          setPage={props.setPage}
          rowsPerPage={props.rowsPerPage}
          setRowsPerPage={props.setRowsPerPage}
        />
      )}
    </React.Fragment>
  );
}

export default SurveysInfo;
