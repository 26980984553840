import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import MainListItems from "./listItems";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Appointment from "./Appointment";
import Doctors from "./Doctors";
import Patients from "./Patients";
import Clinic from "./Clinic";
import Surveys from "./Surveys";
import AccountSetting from "./AccountSetting";
import { useAppContext } from "../services/authContext";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import ClinicDataService from "../services/ClinicDataService";
import AuthService from "../services/AuthService";
import "./BackendStyle.css";
import clinicDefaultImageSrc from "../static/images/clinic_default.png";

const drawerWidth = 240;
const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  role: {
    color: "#999999",
    fontSize: "12px",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  logo: {
    width: 60,
    height: 60,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#ffffff",
    color: "#333333",
    font: "normal normal bold 18px/14px Montserrat-Bold",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    font: "normal normal bold 1.3em / 1em Montserrat- Bold",
    color: "#333333",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    width: "100%",
  },
  container: {
    paddingBottom: theme.spacing(4),
    marginRight: theme.spacing(10),
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  sidebar: {
    height: "100%",
  },
  button: {
    textTransform: "none",
    backgroundColor: "#3553b2",
    color: "#fff",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#3553b2",
      color: "#fff",
    },
    "&:hover::after": {
      content: '" -->"',
    },
  },
}));

export default function Backend(props) {
  const classes = useStyles();
  const { clinicId, setClinicId, setClinicTZs } = useAppContext();
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("Please select your workplace");
  const [clinicList, setClinicList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log(Boolean(anchorEl));
  };

  const clearClinicInfo = () => {
    AuthService.setClinicId(null);
    AuthService.setClinicName(null);
    setClinicId(null);
    setTitle(null);
  };

  const gotoClinicList = () => {
    handleClose();
    clearClinicInfo();
    props.history.push("/");
  };

  const setAccount = () => {
    handleClose();
    props.history.push("/account");
  };

  const signout = () => {
    handleClose();
    clearClinicInfo();
    AuthService.logout();
    props.history.push("/login");
  };

  useEffect(() => {
    const tmpClinicId = AuthService.getClinicId();
    if (tmpClinicId && tmpClinicId.trim() !== "") {
      setClinicId(tmpClinicId);
    } else {
      const accountId = AuthService.getAccountId();
      if (accountId && accountId.trim() !== "") {
        ClinicDataService.findAllAdminClinics(accountId).then(
          (response) => {
            let tmpTimeZones = new Map();
            response.data.forEach((clinic) =>
              tmpTimeZones.set(clinic.id, clinic.timezone)
            );
            setClinicTZs(new Map(tmpTimeZones));
            setClinicList(response.data);
          },
          (error) => console.log(error)
        );
      }
    }
  }, []);

  const selectClinic = (id, name) => (event) => {
    AuthService.setClinicId(id);
    AuthService.setClinicName(name);
    setClinicId(id);
    setTitle(name);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  function configureTitle(title) {
    setTitle(title);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root + " main-page"}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            <div onClick={handleClick}>
              <PersonPinIcon />
              Hi, {localStorage.getItem("userFullName")}
              <br />
              <span className={classes.role}>
                {localStorage.getItem("role")}
              </span>
            </div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={gotoClinicList}>Change Workplace</MenuItem>
              <MenuItem onClick={setAccount}>Account Setting</MenuItem>
              <MenuItem onClick={signout}>Signout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <List disablePadding className={classes.sidebar}>
            <MainListItems setTitle={configureTitle} />
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.container} maxWidth={false}>
            {clinicId ? (
              <Switch>
                <Route path="/appointment" component={Appointment} />
                <Route path="/doctors" component={Doctors} />
                <Route path="/patients" component={Patients} />
                <Route path="/clinic" component={Clinic} />
                <Route path="/survey" component={Surveys} />
                <Route path="/account" component={AccountSetting} />
                {/* <Route component={Dashboard} /> */}
                <Route
                  render={(props) => <Appointment setTitle={configureTitle} />}
                />
              </Switch>
            ) : (
              <Grid container spacing={300}>
                {clinicList.map((clinic) => (
                  <Grid item md={3} className="card-section">
                    <Card
                      className={classes.root + " card-box"}
                      style={{ padding: 30 }}
                    >
                      <CardActionArea className="card-area">
                        <CardMedia
                          component="img"
                          className={classes.media + " card-image"}
                          image={
                            clinic.photoUrl
                              ? clinic.photoUrl
                              : clinicDefaultImageSrc
                          }
                          title={clinic.name}
                        />
                        <CardContent className="card-content">
                          <Typography
                            className="card-content-title"
                            gutterBottom
                            variant="h5"
                            component="h2"
                          >
                            {clinic.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {clinic.description
                              ? JSON.parse(clinic.description).en
                              : ""}
                          </Typography>
                        </CardContent>
                        <CardActions className="card-action">
                          <Button
                            className={classes.button}
                            variant="contained"
                            onClick={selectClinic(clinic.id, clinic.name)}
                          >
                            Enter
                          </Button>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        </main>
      </ThemeProvider>
    </div>
  );
}
